import React from 'react';
import NetworkGraph from './NetworkGraph';

function App() {
  return (
    <div className="App" style={{ width: '100vw', height: '100vh', overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
      <div style={{
        width: '100%',
        height: '8px',
        background: 'linear-gradient(135deg, #1c6941, #72ab32)'
      }} />
      <div style={{ flex: 1, overflow: 'hidden' }}>
        <NetworkGraph />
      </div>
    </div>
  );
}

export default App;
