import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';

const NetworkGraph = () => {
  const svgRef = useRef(null);
  const containerRef = useRef(null);
  const [data, setData] = useState(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const updateDimensions = () => {
      if (containerRef.current) {
        setDimensions({
          width: containerRef.current.clientWidth,
          height: containerRef.current.clientHeight
        });
      }
    };

    window.addEventListener('resize', updateDimensions);
    updateDimensions();

    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  useEffect(() => {
    d3.csv('/IPT-REDBOOK_Scholar_Pivot table - non-rowan-to-rowan collaborations.csv')
      .then(csvData => {
        const nodes = new Set();
        const links = [];
        const riskMap = new Map();

        csvData.forEach(row => {
          const clientUniversity = row['Collaborator Org Name'];
          const clientResearcher = row['Name'];
          const collaborator = row['Author Name'];
          const collaboratorOrg = row['Subject Org Name'];
          const risk = row['Risk'] || 'Low';

          nodes.add(clientUniversity);
          nodes.add(clientResearcher);
          nodes.add(collaborator);
          nodes.add(collaboratorOrg);

          links.push({ source: clientUniversity, target: clientResearcher, type: 'affiliation' });
          links.push({ source: clientResearcher, target: collaborator, type: 'collaboration' });
          links.push({ source: collaborator, target: collaboratorOrg, type: 'affiliation' });

          riskMap.set(collaborator, risk);
          riskMap.set(collaboratorOrg, risk);
        });

        setData({
          nodes: Array.from(nodes).map(id => ({ 
            id, 
            group: csvData.some(row => row['Collaborator Org Name'] === id) ? 'Collaborator Org Name' :
                   csvData.some(row => row['Name'] === id) ? 'Researcher Name' :
                   csvData.some(row => row['Author Name'] === id) ? 'Co-author Name' : 'Co-author Org Name',
            risk: riskMap.get(id) || 'Low'
          })),
          links: links
        });
      })
      .catch(error => console.error("Error loading CSV:", error));
  }, []);

  useEffect(() => {
    if (!data || !svgRef.current || dimensions.width === 0 || dimensions.height === 0) return;

    const svg = d3.select(svgRef.current);
    const { width, height } = dimensions;

    svg.attr('width', width).attr('height', height);
    svg.selectAll('*').remove();

    const g = svg.append('g');

    const color = d3.scaleOrdinal()
      .domain(['Collaborator Org Name', 'Researcher Name', 'Co-author Name', 'Co-author Org Name'])
      .range(['#1f77b4', '#2ca02c', '#ff7f0e', '#d62728']);

    const riskColor = d3.scaleOrdinal()
      .domain(['Very High', 'High', 'Medium', 'Low'])
      .range(['#8b0000', '#ff0000', '#ffa500', '#999999']);

    const simulation = d3.forceSimulation(data.nodes)
      .force('link', d3.forceLink(data.links).id(d => d.id).distance(100))
      .force('charge', d3.forceManyBody().strength(-300))
      .force('center', d3.forceCenter(width / 2, height / 2));

    // Define drag functions
    function dragstarted(event) {
      if (!event.active) simulation.alphaTarget(0.3).restart();
      event.subject.fx = event.subject.x;
      event.subject.fy = event.subject.y;
    }

    function dragged(event) {
      event.subject.fx = event.x;
      event.subject.fy = event.y;
    }

    function dragended(event) {
      if (!event.active) simulation.alphaTarget(0);
      event.subject.fx = null;
      event.subject.fy = null;
    }

    const link = g.append('g')
      .selectAll('line')
      .data(data.links)
      .join('line')
      .attr('stroke', '#999')
      .attr('stroke-opacity', 0.6)
      .attr('stroke-width', 1);

    const node = g.append('g')
      .selectAll('g')
      .data(data.nodes)
      .join('g')
      .call(d3.drag()
        .on('start', dragstarted)
        .on('drag', dragged)
        .on('end', dragended));

    node.each(function(d) {
      const el = d3.select(this);
      if (['Researcher Name', 'Co-author Name'].includes(d.group)) {
        el.append('path')
          .attr('d', 'M480-480q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47ZM160-160v-112q0-34 17.5-62.5T224-378q62-31 126-46.5T480-440q66 0 130 15.5T736-378q29 15 46.5 43.5T800-272v112H160Zm80-80h480v-32q0-11-5.5-20T700-306q-54-27-109-40.5T480-360q-56 0-111 13.5T260-306q-9 5-14.5 14t-5.5 20v32Zm240-320q33 0 56.5-23.5T560-640q0-33-23.5-56.5T480-720q-33 0-56.5 23.5T400-640q0 33 23.5 56.5T480-560Zm0-80Zm0 400Z')
          .attr('fill', color(d.group))
          .attr('transform', 'scale(0.03)');
      } else {
        el.append('path')
          .attr('d', 'M480-120 200-272v-240L40-600l440-240 440 240v320h-80v-276l-80 44v240L480-120Zm0-332 274-148-274-148-274 148 274 148Zm0 241 200-108v-151L480-360 280-470v151l200 108Zm0-241Zm0 90Zm0 0Z')
          .attr('fill', color(d.group))
          .attr('transform', 'scale(0.03)');
      }
    });

    node.append('title')
      .text(d => `${d.id}\nRisk: ${d.risk}`);

    node.append('text')
      .text(d => d.id)
      .attr('x', 12)
      .attr('y', 3)
      .style('font-size', '8px')
      .attr('fill', d => ['Co-author Name', 'Co-author Org Name'].includes(d.group) ? riskColor(d.risk) : '#000')
      .style('font-weight', d => ['Co-author Name', 'Co-author Org Name'].includes(d.group) && d.risk !== 'Low' ? 'bold' : 'normal');

    // Enhanced hover highlight functionality
    node.on('mouseover', highlight)
        .on('mouseout', unhighlight);

    function highlight(event, d) {
      const connectedNodes = new Set([d.id]);
      const relevantLinks = new Set();

      // First pass: find directly connected nodes
      link.each(function(l) {
        if (l.source.id === d.id || l.target.id === d.id) {
          connectedNodes.add(l.source.id);
          connectedNodes.add(l.target.id);
          relevantLinks.add(l);
        }
      });

      // Second pass: specific behavior for different node types
      if (d.group === 'Co-author Org Name') {
        link.each(function(l) {
          if (connectedNodes.has(l.source.id) && l.source.group === 'Co-author Name') {
            if (l.target.group === 'Researcher Name') {
              connectedNodes.add(l.target.id);
              relevantLinks.add(l);
            }
          } else if (connectedNodes.has(l.target.id) && l.target.group === 'Co-author Name') {
            if (l.source.group === 'Researcher Name') {
              connectedNodes.add(l.source.id);
              relevantLinks.add(l);
            }
          }
        });
      } else if (d.group === 'Researcher Name') {
        // For Researcher Name, only highlight directly connected co-authors and their orgs
        link.each(function(l) {
          if (l.source.id === d.id && l.target.group === 'Co-author Name') {
            connectedNodes.add(l.target.id);
            relevantLinks.add(l);
            // Find and add the org for this co-author
            link.each(function(orgLink) {
              if (orgLink.source.id === l.target.id && orgLink.target.group === 'Co-author Org Name') {
                connectedNodes.add(orgLink.target.id);
                relevantLinks.add(orgLink);
              } else if (orgLink.target.id === l.target.id && orgLink.source.group === 'Co-author Org Name') {
                connectedNodes.add(orgLink.source.id);
                relevantLinks.add(orgLink);
              }
            });
          } else if (l.target.id === d.id && l.source.group === 'Co-author Name') {
            connectedNodes.add(l.source.id);
            relevantLinks.add(l);
            // Find and add the org for this co-author
            link.each(function(orgLink) {
              if (orgLink.source.id === l.source.id && orgLink.target.group === 'Co-author Org Name') {
                connectedNodes.add(orgLink.target.id);
                relevantLinks.add(orgLink);
              } else if (orgLink.target.id === l.source.id && orgLink.source.group === 'Co-author Org Name') {
                connectedNodes.add(orgLink.source.id);
                relevantLinks.add(orgLink);
              }
            });
          }
        });
      }

      node.style('opacity', n => connectedNodes.has(n.id) ? 1 : 0.1);
      link.style('opacity', l => relevantLinks.has(l) ? 1 : 0.1);
    }

    function unhighlight() {
      node.style('opacity', 1);
      link.style('opacity', 1);
    }

    simulation.on('tick', () => {
      link
        .attr('x1', d => d.source.x)
        .attr('y1', d => d.source.y)
        .attr('x2', d => d.target.x)
        .attr('y2', d => d.target.y);

      node
        .attr('transform', d => `translate(${d.x},${d.y})`);
    });

    // Add legend
    const legend = svg.append('g')
      .attr('class', 'legend')
      .attr('transform', 'translate(7,20)'); // Adjust these numbers to move the entire legend

    const legendData = ['Collaborator Org Name', 'Researcher Name', 'Co-author Name', 'Co-author Org Name'];

    legendData.forEach((d, i) => {
      const legendRow = legend.append('g')
        .attr('transform', `translate(0, ${i * 30})`); // Adjust the 30 to change vertical spacing between legend items
      
      if (['Researcher Name', 'Co-author Name'].includes(d)) {
        legendRow.append('path')
          .attr('d', 'M480-480q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47ZM160-160v-112q0-34 17.5-62.5T224-378q62-31 126-46.5T480-440q66 0 130 15.5T736-378q29 15 46.5 43.5T800-272v112H160Zm80-80h480v-32q0-11-5.5-20T700-306q-54-27-109-40.5T480-360q-56 0-111 13.5T260-306q-9 5-14.5 14t-5.5 20v32Zm240-320q33 0 56.5-23.5T560-640q0-33-23.5-56.5T480-720q-33 0-56.5 23.5T400-640q0 33 23.5 56.5T480-560Zm0-80Zm0 400Z')
          .attr('fill', color(d))
          .attr('transform', 'scale(0.03) translate(0, 480)');
      } else {
        legendRow.append('path')
          .attr('d', 'M480-120 200-272v-240L40-600l440-240 440 240v320h-80v-276l-80 44v240L480-120Zm0-332 274-148-274-148-274 148 274 148Zm0 241 200-108v-151L480-360 280-470v151l200 108Zm0-241Zm0 90Zm0 0Z')
          .attr('fill', color(d))
          .attr('transform', 'scale(0.03) translate(0, 480)');
      }
      
      legendRow.append('text')
        .attr('x', 35) // Adjust this number to move the text horizontally
        .attr('y', 7) // Adjust this number to move the text vertically
        .text(d);
    });

    // Add risk legend
    const riskLegend = svg.append('g')
      .attr('class', 'risk-legend')
      .attr('transform', 'translate(10,150)'); // Adjust these numbers to move the entire risk legend

    const riskLegendData = ['Very High Risk', 'High Risk', 'Medium Risk', 'Low Risk'];

    riskLegendData.forEach((d, i) => {
      const legendRow = riskLegend.append('g')
        .attr('transform', `translate(0, ${i * 25})`); // Adjust the 25 to change vertical spacing between risk legend items
      
      legendRow.append('rect')
        .attr('width', 40) // Adjust the width of the color box
        .attr('height', 20) // Adjust the height of the color box
        .attr('fill', riskColor(d.split(' ')[0]));
      
      legendRow.append('text')
        .attr('x', 50) // Adjust this number to move the text horizontally
        .attr('y', 15) // Adjust this number to move the text vertically
        .text(d)
        .style('font-weight', d !== 'Low Risk' ? 'bold' : 'normal');
    });

    // Add zoom functionality
    const zoom = d3.zoom()
      .scaleExtent([0.1, 10])
      .on('zoom', (event) => {
        g.attr('transform', event.transform);
      });

    svg.call(zoom);

  }, [data, dimensions]);

  return (
    <div ref={containerRef} style={{ width: '100%', height: '100%' }}>
      <svg ref={svgRef} />
    </div>
  );
};

export default NetworkGraph;
